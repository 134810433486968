import React from 'react'

const styles =
{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"70vh"
  } 
export default function LoadingMain() {
    return (
        
        <div style={styles}>
          Loading please wait {""}<div className="loadingmain"></div>
          </div>        
    )
}
