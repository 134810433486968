import {  GET_ALL_INVOICE_REPORT,  TRANSACTION_REPORT_CRUD_ENDS, TRANSACTION_REPORT_LOADING_STARTS, DELETE_SINGLE_REPORT, TRANSACTION_REPORT_LOADING_ENDS, FILTER_INVOICE_REPORT } from "../actions/types"


const INITIAL_STATE = {
    loading: false,
    reports: [],
    from:"",
    to:"",
    filter:false,
    others:""
}


const transactionReport = ( state = INITIAL_STATE, action) => {
    switch(action.type) {
        case TRANSACTION_REPORT_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case TRANSACTION_REPORT_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case TRANSACTION_REPORT_CRUD_ENDS:
            return {
                ...state,
                loading: false
            }
        case GET_ALL_INVOICE_REPORT:
            return {
                ...state,
                reports: action.payload.data,
                totalQty:action.payload.num
            }
        case FILTER_INVOICE_REPORT:
          
            const {data, inputData:{from, to} ={}} = action.payload
            return {
                ...state,
                reports: data,
                from,
                to,
                filter:true,
            }
        case DELETE_SINGLE_REPORT:
            return {
                ...state,
        reports: state.reports.filter((item) => item.id !== action.payload),
                
            }

        default:
            return state;
    }
}
export default transactionReport;