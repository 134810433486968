import { SET_LOADER, STOP_LOADER } from "../actions/types"

const initialState = {
    loading: false
}

export default function isLoading (state = initialState, action) {
    switch (action.type) {
        case SET_LOADER:
            return {
                ...state,
                loading: true
            }
        case STOP_LOADER:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}
