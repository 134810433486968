import { GET_BUSINESS_PROFILE, PROFILE_LOADING_STARTS, PROFILE_LOADING_ENDS, GET_USER_INFO} from "../actions/types";

const initialState = {
    profile: {},
    loading:null,
    user:{}
};

export default function auth (state = initialState, action) {
    switch (action.type) {
        case PROFILE_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case GET_BUSINESS_PROFILE:
            return {
                ...state,
                profile: action.payload
            }
        case GET_USER_INFO:
         
            return {
                ...state,
                user: action.payload
            }
        case PROFILE_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}