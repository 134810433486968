import React from 'react'
import { Switch, Route } from "react-router-dom";
import CONSTANTS from "../routes/common.json"
import AcceptInvite from "../components/AcceptInvite"
const { USER_SETUP} = CONSTANTS.ROUTES

const AcceptInviteUser = () => {
    return (
      <>
        <Switch> 
          <Route path={`${USER_SETUP}/:hash`} component={AcceptInvite}/>  
        </Switch>
        
        </>     
      
    );
}

export default AcceptInviteUser;