import React from 'react'


const Input = ({col, removeMargin ,label, type, errorMessage, ...otherProps }) => {
    return col ? (
    <div className="form-group mb-4 col-md-6 has-validation">
        <label htmlFor="">{label}</label>
        <input type={type} {...otherProps} className={errorMessage ? "is-invalid form-control inputField" : "form-control inputField"}/>
        <div className="invalid-feedback">
            {errorMessage}
        </div>
    </div>
    ) : (
    <div className="form-group mb-2 has-validation">
        <label htmlFor="">{label}</label>
        <input type={type} {...otherProps} className={errorMessage ? "is-invalid form-control inputField" : "form-control inputField"}/>
        <div className="invalid-feedback">
            {errorMessage}
        </div>
    </div>
    );
}

export default Input;