import { GET_ERRORS, CLEAR_ERRORS } from "../actions/types";

const initialState = {};

export default function Error (state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
           // const { data } = action.payload.response
            return {
                ...state,
                data:action?.payload?.response?.data
            }
        case CLEAR_ERRORS:
            return {};
        default:
            return state;
    }

}