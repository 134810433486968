import axios from "axios"

import { CLEAR_NETWORK_RESPONSE, FETCH_ERROR, HIDE_BANK_MODAL, HIDE_CUSTOMER_MODAL, HIDE_ITEM_MODAL, SHOW_BANK_MODAL, SHOW_CUSTOMER_MODAL, SHOW_ITEM_MODAL, SHOW_RECORD_PAYMENT_MODAL, HIDE_RECORD_PAYMENT_MODAL, SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL, GET_ALL_CUSTOMERS, GET_ALL_ITEMS, UPLOAD_PERCENTAGE , GET_ERRORS, UPLOADED_OBJECT_COMPLETE, UPLOAD_STARTS, UPLOAD_ENDS, CLEAR_ERRORS, SHOW_PAYMENT_SUCESS_MODAL, HIDE_PAYMENT_SUCESS_MODAL, CLEAR_FETCH_ERRORS, SHOW_SEND_RECEIPT_MODAL, HIDE_SEND_RECEIPT_MODAL, SHOW_SEND_RECEIPT_SUCESS_MODAL, REDIRECT_TO, SHOW_TAX_MODAL, HIDE_TAX_MODAL, SHOW_SEND_INVOICE_DRAFT_MODAL} from "./types"
import CONSTANTS from "../routes/common.json"

import {notify} from "../utils/notification"
import process from "../env"
const service_url = process.env.SERVICE_URL

export const clearNetworkResponse = () => ({ type: CLEAR_NETWORK_RESPONSE})

export const networkResponseError = () => ({type: FETCH_ERROR})

export const showItemModal = () => ({ type: SHOW_ITEM_MODAL})

export const hideItemModal = () => ({ type: HIDE_ITEM_MODAL})

export const showCustomerModal = () => ({ type: SHOW_CUSTOMER_MODAL})

export const hideCustomerModal = () => ({type: HIDE_CUSTOMER_MODAL})

export const showBankModal = () => ({type: SHOW_BANK_MODAL})

export const hideBankModal = () => ({type: HIDE_BANK_MODAL})

export const hideRecordPaymentModal =()=>({
    type:HIDE_RECORD_PAYMENT_MODAL
})

export const showRecordPaymentModal =()=>({type:SHOW_RECORD_PAYMENT_MODAL})

export const showConfirmationModal =()=>{
return {
    type:SHOW_CONFIRMATION_MODAL
}
}

export const hideConfirmationModal =()=> {
    return (dispatch) => {
        dispatch({type:HIDE_CONFIRMATION_MODAL})
        dispatch(clearNetworkResponse())
    }
}
export const hideRecordPaymentSuccessModal =()=> {
    return (dispatch) => {
        dispatch({type:HIDE_PAYMENT_SUCESS_MODAL})
        dispatch(clearNetworkResponse())
    }
}

export const showRecordPaymentSuccessModal =()=> {
    return (dispatch) => {
        dispatch({type:SHOW_PAYMENT_SUCESS_MODAL})
        dispatch(clearNetworkResponse())
    }
}
export const showSendReceiptModal =(id)=> {
    
    return (dispatch) => {
        dispatch({type:SHOW_SEND_RECEIPT_MODAL, payload:id})
        dispatch(clearNetworkResponse())
    }
}



export const bulkUpload = (data,objectType, options, dashboard)=>{
    return async(dispatch)=> {
            dispatch({type:UPLOAD_STARTS})
            const sendDataUrl = axios.post(`${service_url}${objectType}/import`, data, options)
            const getNewdataUrl = axios.get(`${service_url}${objectType}`)
            let test = `${service_url}${objectType}`
            console.log("HAHAHAHAH", test)
            axios.all([sendDataUrl, getNewdataUrl])
            .then(axios.spread((...responses) => {
                if (responses[0].status === 200 && responses[1].status === 200) {                 
                    dispatch(clearNetworkResponse())
                    if(dashboard) {
                        dispatch({ type: REDIRECT_TO, payload: CONSTANTS.ROUTES.ITEM })
                        dispatch(clearNetworkResponse())
                    }
                    if (objectType==="items"){
                        dispatch({
                            type:GET_ALL_ITEMS,
                            payload:responses[1].data.data
                        })
                    }
                    if (objectType==="customers"){
                        dispatch({
                            type:GET_ALL_CUSTOMERS,
                            payload:responses[1].data.data
                        })
                        if(dashboard) {
                            dispatch({ type: REDIRECT_TO, payload: CONSTANTS.ROUTES.CUSTOMER_LIST })
                            dispatch(clearNetworkResponse())
                        }
                    }
                  dispatch({ type:UPLOAD_PERCENTAGE,  payload:100})
                  dispatch({type:"TOTAL_FILE_SIZE", payload:""})
                  dispatch({type:"CURRENT_FILE_SIZE_UPLOADED", payload:""})
                  dispatch({ type:UPLOADED_OBJECT_COMPLETE, payload:true })

                return notify("Item uploaded successfully", "success")
           
                }
            }))
            .then(data =>  {
                dispatch({ type:UPLOAD_PERCENTAGE,  payload:0})
                dispatch({type:UPLOAD_ENDS})
                
            })

            .catch((error) => {
                dispatch({type:UPLOAD_ENDS})
                if(error.response){
                    dispatch({ type: GET_ERRORS, payload: error})
                    return notify("There are some errors while uploading your files", "error")
                }else{
                    return notify("Check your internet", "error")
                }
           })
    }
}

export const clearError = () => ({ type: CLEAR_ERRORS })

export const clearFetchError =()=>({type:CLEAR_FETCH_ERRORS})

export const hideIssueReceiptModal = () => ({type: HIDE_SEND_RECEIPT_MODAL})

export const showSendReceiptSuccessModal =()=>({type:SHOW_SEND_RECEIPT_SUCESS_MODAL})

export const showTaxModal =()=>({ type:SHOW_TAX_MODAL})
export const hideTaxModal =()=>({ type:HIDE_TAX_MODAL})

export const showSendInvDraft =()=>({type:SHOW_SEND_INVOICE_DRAFT_MODAL})