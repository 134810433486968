import { DELETE_BANK, GET_ALL_BANKS, BANK_LOADING_STARTS, BANK_LOADING_ENDS,  BANK_NETWORK_RESPONSE, GET_BANK } from "../actions/types";

const initialState = {
    banks: [],
    bank:null,
    loading:null,
    banksStatus:null
}

export default function auth (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_BANKS:
            return {
                ...state,
                banks: action.payload
            }
        case GET_BANK:
            return {
                ...state,
                bank: action.payload
            }
        case BANK_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case BANK_LOADING_ENDS:
            return {
                ...state,
                loading:false
            }

        case BANK_NETWORK_RESPONSE:      
            return {
                ...state,
                bankStatus: action.payload[0].data.status==="success"?true:false,
            }
        case "CLEAR_BANK_RESPONSE":      
            return {
                ...state,
                bankStatus: null
            }

        case DELETE_BANK:
            return {
                ...state,
                banks: state.banks.filter(bank => bank.id !== action.payload)
            }
        default:
            return state;
    }
}