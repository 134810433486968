import setAuthToken from "../utils/setAuthToken";
import { GET_ERRORS, IS_LOADING, IS_VERIFIED, LOG_OUT_USER, NOT_LOADING, NOT_VERIFIED, REDIRECT_TO, SET_CURRENT_USER, CHANGE_PASSWORD_STARTS, CHANGE_PASSWORD_ENDS, FORGOT_PASSWORD_SUCCESS, IS_ADMIN, NOT_ADMIN } from "./types";
import { notify } from "../utils/notification"
import jwt_decode from "jwt-decode"
import { clearNetworkResponse } from "./networkActions";
import axios from 'axios'
import process from "../env"
import CONSTANTS from "../routes/common.json"

const { DASHBOARD, COMPLETE_BUSINESS_PROFILE, ACCOUNT_CREATED, SETTINGS, LOGIN} = CONSTANTS.ROUTES
const service_url = process.env.SERVICE_URL


export const registerUser = (data) => {
    return async(dispatch) => {
        try {
            dispatch({ type: IS_LOADING })
            const signUp = await axios.post(`${service_url}register`, data)        
            dispatch({ type: REDIRECT_TO, payload: ACCOUNT_CREATED })
            dispatch(clearNetworkResponse())
            dispatch({ type: NOT_LOADING })
          
            localStorage.setItem("resendID", signUp.data.id)
            localStorage.setItem("hash", signUp.data.hash)
            return notify(signUp.data.message, "success")
        } catch (error) {
             dispatch({ type: NOT_LOADING })
           // console.log(error, "EMAIL!!!!!")
            if(error.response){
                dispatch({ type: GET_ERRORS, payload: error})
                dispatch(clearNetworkResponse())
                // return notify(error.response.data[0], "error")
            }else{
                return notify("Check your internet", "error")
            }
           // dispatch({ type: NOT_LOADING })
        }
    }
}



// Login - Get User Token
export const loginUser = (data) => {
    return async(dispatch) => {
        try {
            dispatch({ type: IS_LOADING })
            const login = await axios.post(`${service_url}login`, data)
            dispatch({ type: NOT_LOADING })
            // Save to localStorage
            const { access_token, business_name, business_logo, isAdmin, currency } = login.data;

            localStorage.setItem("access_token", access_token);
            localStorage.setItem("logo", business_logo)
            localStorage.setItem("isAdmin", isAdmin)
            localStorage.setItem("currency", currency)
            console.log("hello", login.data)
            // Set token to Auth header
            setAuthToken(access_token);
            // Decode token to get user data
            const decoded = jwt_decode(access_token);
            // Set current user
            dispatch(setCurrentUser(decoded));
            if(login.data.business_profile) {
                const decoded = jwt_decode(localStorage.access_token);
                const businessTerms = await axios.get(`${service_url}business-profile/${decoded.sub}`)
               
               localStorage.setItem("businessProfile",JSON.stringify(businessTerms?.data?.result))
                localStorage.setItem('business_name', business_name.toUpperCase())
                dispatch({ type: REDIRECT_TO, payload: DASHBOARD})
                // window.location.href = DASHBOARD

                dispatch(clearNetworkResponse())
            }else {
               
                dispatch({ type: REDIRECT_TO, payload: COMPLETE_BUSINESS_PROFILE})
                dispatch(clearNetworkResponse())
            }
            return notify("Logged In", "success")
        } catch (error) {
            dispatch({ type: NOT_LOADING })
            if(error.response){
                dispatch({ type: GET_ERRORS, payload: error})
                return notify(error?.response?.data?.message, "error")
            }

        
                return notify("Something went wrong", "error")
            
        }
    }
};


// Set logged in user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};


export const setAdminStatus = (isAdmin) => {
    return {
        type: isAdmin ? IS_ADMIN : NOT_ADMIN,
        payload: isAdmin
    }
}
// Log user out
export const logoutUser = () => (dispatch) => {
    dispatch({
            type: LOG_OUT_USER
        })
        // Remove token from localStorage
    localStorage.removeItem("jwtToken");
    localStorage.clear()
        // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
    // window.location.href = LOGIN
};

export const resendEmailVerification = () => {
    return async(dispatch) => {
        try {
            await axios.get(`${service_url}email/account-verified/${localStorage.getItem('hash')}/${localStorage.getItem('resendID')}`)
            dispatch(clearNetworkResponse())
            return notify("Mail Resent", "success")
        } catch (error) {
            
            if(error.response){
                dispatch({ type: GET_ERRORS, payload: error})
                dispatch(clearNetworkResponse())
                return notify("Some thing went wrong", "error")
                // return notify(error.response.data[0], "error")
            }else{
                return notify("Check your internet", "error")
            }
        }
    }
}




//verify account
export const verifyAccount = (hash, id) => {
    return async(dispatch) => {
        dispatch({ type: IS_LOADING })
        try {
            const response = await axios.get(`${service_url}email/account-verified/${hash}/${id}`)
            if(response){
                dispatch({ type: NOT_LOADING })
                dispatch({ type: IS_VERIFIED })
            }
        } catch (error) {
            dispatch({ type: NOT_LOADING })
            dispatch({ type: NOT_VERIFIED })
          
        }
    }
}

export const changePassword =(data)=>{
    return async (dispatch)=>{
        dispatch({type:CHANGE_PASSWORD_STARTS})
        try{
            await axios.post(`${service_url}change-password`, data)
            dispatch({ type: REDIRECT_TO, payload: SETTINGS})
            dispatch(clearNetworkResponse())
            dispatch({type:CHANGE_PASSWORD_ENDS})
            return notify("password changed successfully", "success")
        }

        catch (error) {
            dispatch({type:CHANGE_PASSWORD_ENDS})
            if(error.response){
                dispatch({ type: GET_ERRORS, payload: error})
                dispatch(clearNetworkResponse())
                return notify(error.response.data.message, "error")
            }else{
                return notify("Check your internet", "error")
            }
        }
    }
}


//Accept Invite
export const AcceptInvite = (data) => {
    console.log("Let go....");
    return async(dispatch) => {
        try {
            dispatch({ type: IS_LOADING }) 
            const invite = await axios.post(`${service_url}employees/setup`, data) 

            // Save to localStorage
            const { access_token, business_name, business_logo } = invite.data;
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("logo", business_logo)
            // Set token to Auth header
            setAuthToken(access_token);
            // Decode token to get user data
            const decoded = jwt_decode(access_token);
            // Set current user
            dispatch(setCurrentUser(decoded));
            //remove business invite after successful registration
            localStorage.removeItem("invited by")

            if(invite.data.business_profile) {
                dispatch({ type: REDIRECT_TO, payload: DASHBOARD})              
                localStorage.setItem('business_name', business_name.toUpperCase())
              
                dispatch(clearNetworkResponse())
            }
            
        } catch (error) {
           
            if(error.response){
                dispatch({ type: GET_ERRORS, payload: error})
                dispatch(clearNetworkResponse())
                // return notify(error.response.data[0], "error")
            }else{
                return notify("Check your internet", "error")
            }
            dispatch({ type: NOT_LOADING })
        }
    }
}

export const forgotPassword =(data)=>{

    return async (dispatch)=>{
        dispatch({type:IS_LOADING})
        try{
           let res =  await axios.post(`${service_url}forgot-password`, data)
          
            dispatch(clearNetworkResponse())
            dispatch({type:FORGOT_PASSWORD_SUCCESS, payload:res.status})
            dispatch({type:NOT_LOADING})
            return notify(res?.data?.message, "success")
        }

        catch (error) {
            dispatch({type:NOT_LOADING})
            if(error.response){
                dispatch({ type: GET_ERRORS, payload: error})
                dispatch(clearNetworkResponse())
                return notify(error.response.data.message, "error")
            }else{
                return notify("Check your internet", "error")
            }
        }
    }
}


export const resetPassword =(data)=>{
    //console.log("data", data)
  
    return async (dispatch)=>{
        dispatch({type:IS_LOADING})
        try{
           let res =  await axios.post(`${service_url}reset-password`, data)

           dispatch({ type: REDIRECT_TO, payload: LOGIN})
            dispatch({ type: NOT_LOADING })
            dispatch(clearNetworkResponse())
            return notify(res?.data?.message, "success")
        }

        catch (error) {
            dispatch({type:NOT_LOADING})
            if(error.response){
                dispatch({ type: GET_ERRORS, payload: error})
                dispatch(clearNetworkResponse())
                return notify(error.response.data.message, "error")
            }else{
                return notify("Check your internet", "error")
            }
        }
    }
}


