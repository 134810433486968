//Validate Password Input
export const mustContainsOneLowercaseAlphabet = new RegExp("(?=.*[a-z])")
export const mustContainsOneUppercaseAlphabet = new RegExp("(?=.*[A-Z])")
export const mustContainsOneNumericCharacter = new RegExp("(?=.*[0-9])")
export const mustbeAValidEmail = /(^\w.*@\w+\.\w)/

export const validatePasswordInput = (password) => {
    if(!mustContainsOneNumericCharacter.test(password)){
        return [false, "must contain at least one numeric character"]
    } else if(!mustContainsOneUppercaseAlphabet.test(password)){
        return [false, "must contain at least one uppercase character"]
    } else if(!mustContainsOneLowercaseAlphabet.test(password)) {
        return [false, "must contain at least one lowercase character"]
    }
    return true
}

export const validateEmailInput =(email)=>{
    if(email && /(^\w.*@\w+\.\w)/.test(email)){
        return [false, "All looking good"]
    } 
    if(email){
        return [false, "Keep Going..."]
    }    
}

