import {DASHBOARD_LOADING_ENDS, DASHBOARD_LOADING_STARTS, GET_INVOICE_STATS, GET_INVOICE_TOP_CUSTOMERS, GET_INVOICE_TOP_ITEMS, GET_ACCOUNT_STATUS} from "../actions/types";

const initialState = {
    loading:"idle",
    invoiceStats:{},
    topItems:[],
    topCustomers:[],
    accountStatus:""
};

export default function auth (state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_LOADING_STARTS:  
            return {
                ...state,
                loading:true
            }     
            case DASHBOARD_LOADING_ENDS:
                return {
                    ...state,
                    loading:false
                }
        case GET_INVOICE_STATS:
            return {
                ...state,
                invoiceStats:action.payload
            }
        case GET_INVOICE_TOP_CUSTOMERS:
            return {
                ...state,
                topCustomers:action.payload
            }
    
        case GET_INVOICE_TOP_ITEMS:
            return {
                ...state,
                topItems:action.payload
            }
        case GET_ACCOUNT_STATUS:
            return {
                ...state,
                accountStatus:action.payload
            }

            case "ITEMS":
                return {
                    ...state,
                    topItems:action.payload
                }
     
        default:
            return state;
    }
}