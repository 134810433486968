import { CRUD_TAX_ENDS, CRUD_TAX_STARTS, GET_ALL_TAXES } from "../actions/types"


const INITIAL_STATE = {
    loading: false,
    taxes: [],
}


const taxReducer  =( state = INITIAL_STATE, action) => {
    switch(action.type) {
        case CRUD_TAX_STARTS:
            return {
                ...state,
                loading: true
            }
        case CRUD_TAX_ENDS:
            return {
                ...state,
                loading: false
            }
        case GET_ALL_TAXES:
            return {
                ...state,
                taxes: action.payload
            }
        default:
            return state;
    }
}


export default taxReducer;