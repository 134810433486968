import {
  CLEAR_NETWORK_RESPONSE,
  REDIRECT_TO,
  SHOW_NETWORK_RESPONSE,
  SHOW_NETWORK_RESPONSE_MOD,
  FETCH_ERROR,
  IS_VERIFIED,
  NOT_VERIFIED,
  CLEAR_NOT_VERIFIED,
  HIDE_ITEM_MODAL,
  SHOW_ITEM_MODAL,
  SHOW_CUSTOMER_MODAL,
  HIDE_CUSTOMER_MODAL,
  SHOW_BANK_MODAL,
  HIDE_BANK_MODAL,
  SHOW_RECORD_PAYMENT_MODAL,
  HIDE_RECORD_PAYMENT_MODAL,
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
  START_OPERATION_IN_PROGRESS,
  END_OPERATION_IN_PROGRESS,
  UPLOAD_PERCENTAGE,
  TOTAL_FILE_SIZE,
  CURRENT_FILE_SIZE_UPLOADED,
  UPLOADED_OBJECT,
  UPLOADED_OBJECT_COMPLETE,
  UPLOAD_ENDS,
  UPLOAD_STARTS,
  CLEAR_FETCH_ERRORS,
  SHOW_PAYMENT_SUCESS_MODAL,
  HIDE_PAYMENT_SUCESS_MODAL,
  SHOW_SEND_RECEIPT_MODAL,
  HIDE_SEND_RECEIPT_MODAL,
  SHOW_SEND_RECEIPT_SUCESS_MODAL,
  HIDE_TAX_MODAL,
  SHOW_TAX_MODAL,
  SHOW_SEND_INVOICE_DRAFT_MODAL,
  HIDE_SEND_INVOICE_DRAFT_MODAL,
} from "../actions/types";

const initialState = {
  response: null,
  statusCode: null,
  redirectTo: null,
  fetchError: false,
  isVerified: false,
  notVerified: false,
  showItemModal: false,
  showCustomerModal: false,
  showBankModal: false,
  showRecorderPaymentModal: false,
  showConfirmationModal: false,
  currentID: null,
  operationInProgress: false,
  uploadPercentage: 0,
  TotalFileSize: null,
  currentFileSizeUploaded: null,
  uploadedObject: null,
  uploadStarts: false,
  showRecordPaymentSuccessModal: false,
  showSendReceiptModal: false,
  showSendReceiptSuccessModal: false,
  showTaxModal: false,
  showSendInvoiceDraft: false,
};

export default function networkResponse(state = initialState, action) {
  switch (action.type) {
    case SHOW_NETWORK_RESPONSE:
      return {
        ...state,
        response: action.payload?.message,
        statusCode: action.payload?.status,
      };
    case SHOW_NETWORK_RESPONSE_MOD:
      return {
        ...state,
        response: action.payload,
        statusCode: action.payload,
      };
    case CLEAR_NETWORK_RESPONSE:
      return {
        ...state,
        response: null,
        statusCode: null,
        redirectTo: null,
        fetchError: false,
      };
    case REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload,
      };
    case FETCH_ERROR:
      // console.log("fecterror dispatched");
      return {
        ...state,
        fetchError: true,
      };

    case CLEAR_FETCH_ERRORS:
      return {
        ...state,
        fetchError: false,
        statusCode: null,
      };
    case IS_VERIFIED:
      return {
        ...state,
        isVerified: true,
      };
    case NOT_VERIFIED:
      return {
        ...state,
        notVerified: true,
      };
    case CLEAR_NOT_VERIFIED:
      return {
        ...state,
        notVerified: false,
      };
    case SHOW_ITEM_MODAL:
      return {
        ...state,
        showItemModal: true,
      };
    case HIDE_ITEM_MODAL:
      return {
        ...state,
        showItemModal: false,
      };
    case SHOW_CUSTOMER_MODAL:
      return {
        ...state,
        showCustomerModal: true,
      };
    case SHOW_RECORD_PAYMENT_MODAL:
      return {
        ...state,
        showRecorderPaymentModal: true,
      };
    case HIDE_RECORD_PAYMENT_MODAL:
      return {
        ...state,
        showRecorderPaymentModal: false,
      };
    case HIDE_CUSTOMER_MODAL:
      return {
        ...state,
        showCustomerModal: false,
      };
    case SHOW_BANK_MODAL:
      return {
        ...state,
        showBankModal: true,
      };
    case HIDE_BANK_MODAL:
      return {
        ...state,
        showBankModal: false,
      };

    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationModal: true,
      };
    case HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationModal: false,
      };
    case START_OPERATION_IN_PROGRESS:
      return {
        ...state,
        operationInProgress: true,
      };
    case END_OPERATION_IN_PROGRESS:
      return {
        ...state,
        operationInProgress: false,
      };
    case UPLOAD_PERCENTAGE:
      return {
        ...state,
        uploadPercentage: action.payload,
      };
    case TOTAL_FILE_SIZE:
      return {
        ...state,
        TotalFileSize: action.payload,
      };
    case CURRENT_FILE_SIZE_UPLOADED:
      return {
        ...state,
        currentFileSizeUploaded: action.payload,
      };
    case UPLOADED_OBJECT:
      return {
        ...state,
        uploadedObject: action.payload,
      };
    case UPLOADED_OBJECT_COMPLETE:
      return {
        ...state,
        uploadCompletes: action.payload,
      };
    case UPLOAD_STARTS:
      return {
        ...state,
        uploadStarts: true,
      };
    case UPLOAD_ENDS:
      return {
        ...state,
        uploadStarts: false,
      };
    case SHOW_PAYMENT_SUCESS_MODAL:
      return {
        ...state,
        showRecordPaymentSuccessModal: true,
      };
    case HIDE_PAYMENT_SUCESS_MODAL:
      return {
        ...state,
        showRecordPaymentSuccessModal: false,
      };
    case SHOW_SEND_RECEIPT_MODAL:
      return {
        ...state,
        showSendReceiptModal: true,
        currentID: action.payload,
      };
    case HIDE_SEND_RECEIPT_MODAL:
      return {
        ...state,
        showSendReceiptModal: false,
      };

    case SHOW_SEND_RECEIPT_SUCESS_MODAL:
      return {
        ...state,
        showSendReceiptSuccessModal: true,
      };
    case HIDE_TAX_MODAL:
      return {
        ...state,
        showTaxModal: false,
      };
    case SHOW_TAX_MODAL:
      return {
        ...state,
        showTaxModal: true,
      };
    case SHOW_SEND_INVOICE_DRAFT_MODAL:
      return {
        ...state,
        showSendInvoiceDraft: true,
      };
    case HIDE_SEND_INVOICE_DRAFT_MODAL:
      return {
        ...state,
        showSendInvoiceDraft: false,
      };
    default:
      return state;
  }
}
