import React from 'react'

function EmailSent() {
    return (
       <>
            <div style={{ textAlign:"center"}} >
              <svg
             
                width="92"
                height="92"
                viewBox="0 0 92 92"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M76.6667 45.9994C76.6667 54.1327 73.4358 61.9328 67.6847 67.684C61.9336 73.4351 54.1334 76.666 46.0001 76.666C37.8668 76.666 30.0666 73.4351 24.3155 67.684C18.5644 61.9328 15.3334 54.1327 15.3334 45.9994C15.3334 37.866 18.5644 30.0659 24.3155 24.3147C30.0666 18.5636 37.8668 15.3327 46.0001 15.3327C48.9134 15.3327 51.7501 15.7543 54.4334 16.521L60.4517 10.5027C56.0051 8.66268 51.1367 7.66602 46.0001 7.66602C40.9661 7.66602 35.9814 8.65754 31.3305 10.584C26.6797 12.5104 22.4539 15.334 18.8943 18.8936C11.7054 26.0825 7.66675 35.8327 7.66675 45.9994C7.66675 56.166 11.7054 65.9162 18.8943 73.1051C22.4539 76.6647 26.6797 79.4883 31.3305 81.4147C35.9814 83.3412 40.9661 84.3327 46.0001 84.3327C56.1667 84.3327 65.9169 80.294 73.1058 73.1051C80.2947 65.9162 84.3334 56.166 84.3334 45.9994H76.6667ZM30.3217 38.6394L24.9167 44.0827L42.1667 61.3327L80.5001 22.9993L75.0951 17.556L42.1667 50.4844L30.3217 38.6394Z"
                  fill="#047B40"
                />
              </svg>
           <p style ={{margin:".4rem", fontWeight:1000}}>Email Sent</p>
        <p>Kindly check your email for a link to reset Password</p>
            
            </div>
        </>
    )
}

export default EmailSent
