import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import isLoadingReducer from "./isLoadingReducer"
import authReducer from "./Auth/index"
import networkResponse from "./networkResponse"
import customerReducer from "./customerReducer"
import itemReducer from "./itemReducer"
import businessProfile from "./businessProfileReducer"
import invoiceReducer from "./InvoiceReducer"
import bankReducer from "./bankReducer"
import recordPaymentReducer from "./recordPaymentReducer"
import dashBoardReducer from "./dashboardReducer"
import userRolesReducer from "./userRolesReducer"
import modalReducer from "./modalReducer"
import taxReducer from "./taxReducer";
import transactionReport from "./TransactionReportRedux"
import formDataReducer from "./formDataReducer"
import loaderReducer from "./loaderReducer"
import quoteReducer from "./quoteReducer"


export default combineReducers({
    networkResponse,
    auth:authReducer,
    errors: errorReducer,
    isLoading: isLoadingReducer,
    customers: customerReducer,
    items: itemReducer,
    business_profile: businessProfile,
    invoices:invoiceReducer,
    banks: bankReducer,
    recordPayment: recordPaymentReducer,
    dashboard:dashBoardReducer,
    userRoles:userRolesReducer,
    modal:modalReducer,
    taxes: taxReducer,
    transactionReport,
    formData: formDataReducer,
    loader: loaderReducer,
    quote:quoteReducer,
});