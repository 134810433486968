import {
  DELETE_INVOICE,
  GET_ALL_INVOICE,
  INVOICE_LOADING_STARTS,
  INVOICE_LOADING_ENDS,
  GET_SINGLE_INVOICE,
  INVOICE_CRUD_STARTS,
  INVOICE_CRUD_ENDS
} from "../actions/types";

const initialState = {
  invoice: [],
  singleInvoice:{},
  loading: false,
  crudInProgress:false
};

export default function invoice(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_INVOICE:
      return {
        ...state,
        invoice: action.payload.data,
        totalQty:action.payload.num
      };
    case INVOICE_LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case INVOICE_LOADING_ENDS:
      return {
        ...state,
        loading: false,
      };
    case GET_SINGLE_INVOICE:
      console.log(action.payload, "payload");
      return {
        ...state,
        singleInvoice: action.payload.data,
        totalQty:action.payload.num
      };
    case DELETE_INVOICE:
      return {
        ...state,
        invoice: state.invoice?.data.filter((item) => item.id !== action.payload),
      };
    case INVOICE_CRUD_STARTS:
      return {
        ...state,
      crudInProgress:true
      };
    case INVOICE_CRUD_ENDS:
      return {
        ...state,
      crudInProgress:false
      };
    default:
      return state;
  }
}
