import React, {Suspense, lazy} from "react";
import AuthPage from "./pages/AuthPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CONSTANTS from "./routes/common.json";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import store from "./store";
import { logoutUser, setAdminStatus, setCurrentUser } from "./actions/authActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/utils/PrivateRoute";
import LoadingMain from "./components/LoadingMain";
import AcceptInvite from "./pages/AcceptInviteIndex";
import ResetPasswordIndex from "./pages/ResetPasswordIndex"
import process from "./env"
import axios from "axios";
const preLoadDashBoard = import("./pages/Dashboard")
//const preLoadResetPassword = import("./pages/ResetPasswordIndex")
const preLoadCompleteBusinessProfile = import("./components/CompleteBusinessProfile")
const Dashboard = lazy(() => preLoadDashBoard);
const AccountCreated = lazy(() => import("./components/AccountCreated"));
const Verification = lazy(() => import("./components/Verification"));
const CompleteBusinessProfile = lazy(()=> preLoadCompleteBusinessProfile);
//const PasswordReset= lazy(()=> preLoadResetPassword );

const service_url = process.env.SERVICE_URL

const {
  LOGIN,
  DASHBOARD,
  ACCOUNT_CREATED,
  VERIFICATION_STATUS,
  COMPLETE_BUSINESS_PROFILE,
  USER_SETUP,
  RESET_VERIFICATION_PASSWORD,
} = CONSTANTS.ROUTES;

if (localStorage.access_token) {
  // Set auth token header auth
  setAuthToken(localStorage.access_token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.access_token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    // store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = LOGIN;
  }
}



const App = () => {
  return (
    <>
      <Router getUserConfirmation={(message, callback) => {
      // this is the default behavior
      const allowTransition = window.confirm(message);
      callback(allowTransition);
      }}>
      <Suspense fallback={<LoadingMain />}>
        <Switch>
          { /* <ErrorBoundary> */}
            <Route path={LOGIN} component={AuthPage} />
            <Route path={USER_SETUP} component={AcceptInvite} />
            <Route path={ACCOUNT_CREATED} component={AccountCreated} />
            <Route
              path={`${VERIFICATION_STATUS}/:hash/:user_id`}
              component={Verification}
            />
            <Route
              path={`${RESET_VERIFICATION_PASSWORD}/:hash`}
              component={ResetPasswordIndex}
            />  
            <PrivateRoute
              path={COMPLETE_BUSINESS_PROFILE}
              component={CompleteBusinessProfile}
            />
            <PrivateRoute path={DASHBOARD} component={Dashboard} />
          {/* </ErrorBoundary> */}
        </Switch>
        </Suspense>
        <ToastContainer />
      </Router>
    </>
  );
};

export default App;
