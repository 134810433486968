import axios from "axios"
import process from "../env"
import jwt_decode from "jwt-decode";

const service_url = process.env.SERVICE_URL

export const fetchInvoiceById = (id) => {
    return axios.get(`${service_url}invoice/${id}`)
}

export const fetchPaymentModes = () => {
    return axios.get(`${service_url}/invoice/payment-modes`)
}


export const fetchAllCustomers =()=>{
    return axios.get(`${service_url}customers`)
}
export const fetchAllCustomersNoPaginate =()=>{
    const res = axios.get(`${service_url}customers/no-paginate`)
    return res
}

export const fetchCustomerById = (id) => {
    return axios.get(`${service_url}customers/${id}`)
}
export const deleteCustomerById = (id) => {
    return axios.delete(`${service_url}customers/${id}`)
}

export const fetchItemById = (id) => {
    return axios.get(`${service_url}items/${id}`)
}

export const fetchBusinessProfile = (id) => {
    return axios.get(`${service_url}business-profile/${id}`)
}

export const fetchAllBanks = (id) => {
    return axios.get(`${service_url}business-profile/bank-details/`)
}

export const fetchBankById = (id) => {
    return axios.get(`${service_url}business-profile/bank-details/${id}`)
}

export const fetchInvoiceByItemId =(id)=>{
    return axios.get(`${service_url}items/invoice/${id}`)

}
export const fetchInvoiceByCustomerId =(id)=>{
    return axios.get(`${service_url}customers/invoice/${id}`)

}
export const fetchAccessControlById=(id)=>{
    
    return axios.get(`${service_url}employees/permissions/${id}`)
}
export const validateUser= async(hash)=>{
    let result = await axios.get(`${service_url}employees/validate/${hash}`)
    localStorage.setItem("invited by",  result.data.business_name)
    return result
}


export const logoutUser = async () => {
    return await axios.post(`${service_url}logout`)
}


export const fetchAllTaxes =()=>{
    return axios.get(`${service_url}tax`)
}
export const fetchTaxById = (id) => {
    return axios.get(`${service_url}tax/${id}`)
}

export const fetchLoggedInUser = () => {
    return axios.get(`${service_url}user-info`)
}

export const loadBusinessProfile =()=>{
    const decoded = jwt_decode(localStorage.access_token);
    return axios.get(`${service_url}business-profile/${decoded.sub}`)
}

export const createTaxFromApi =(data)=>{
    let result = axios.post(`${service_url}tax`, data)
    return  result
}

export const fetchQuoteById = (id) => {
    return axios.get(`${service_url}quote/${id}`)
}