import { DELETE_ITEM, GET_ALL_ITEMS, ITEM_NETWORK_RESPONSE, ITEMS_LOADING_STARTS, ITEMS_LOADING_ENDS, FILTER_ITEM, GET_INVOICES_BY_ITEM_ID } from "../actions/types";

const initialState = {
    items: [],
    filteredItems: [],
    loading:false,
    itemStatus:null,
    CurrentItemId:null,
    invoices:[]
};

export default function auth (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ITEMS:
          
            return {
                ...state,
                items: action.payload?.data,
                filteredItems: action.payload?.data,
                totalQty:action.payload?.num
            }
        case ITEMS_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case ITEMS_LOADING_ENDS:
            return {
                ...state,
                loading:false
            }

        case ITEM_NETWORK_RESPONSE:      
            return {
                ...state,
                itemStatus: action.payload[0].data.status==="success"?true:false,
            }
        case "CLEAR_ITEM_RESPONSE":      
            return {
                ...state,
                itemStatus: null
            }

        case DELETE_ITEM:
            return {
                ...state,
                items: state.items?.data.filter(item => item.id !== action.payload)
            }
        case FILTER_ITEM:
            console.log("action", action.payload)
            return {
                ...state,
                items: state.items.filter(item => item.item_type.toLowerCase() === action.payload.toLowerCase())
            }
        case GET_INVOICES_BY_ITEM_ID:
            return {
                ...state,
                invoices: action.payload
            }
        default:
            return state;
    }
}